<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 投诉管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-tabs v-model="type" type="card" @tab-click="tabClick">
			<el-tab-pane label="待审核" name="1">
				<item :activeType="type" type="1" />
			</el-tab-pane>
			<el-tab-pane label="申诉成功" name="2">
				<item :activeType="type" type="2" />
			</el-tab-pane>
			<el-tab-pane label="申诉失效" name="3">
				<item :activeType="type" type="3" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import item from './item'
	export default {
		name: 'complain',
		components: {
			item
		},
		data() {
			return {
				type: '1',
			};
		},
		created() {},
		methods: {
			tabClick(e) {
				//切换type
				this.type = e.props.name
			}
		}
	}
</script>
<style scoped>

</style>
